footer {
	background-color: $verde;
	text-align: center;
	font-family: Quicksand;
	font-size: 0.8rem;
	padding: 10px;

	ul {
		color: $rosa;

		li {
			padding: 2px;

			a {
				color: $rosa;

				&:hover {
					cursor: pointer;
				}
			}
			
			.instagram {
				font-size: 1.2rem;
			}

			.privacy-btn {
				font-size: 0.9rem;
			}
		}
	}
}
