::placeholder {
	color: $rosa;
	opacity: 1;
}
:-ms-input-placeholder {
	color: $rosa;
}
::-ms-input-placeholder {
	color: $rosa;
}

form.contact-form {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	input[type="text"] {
		appearance: none;
		width: 60%;
		height: 40px;
		margin-bottom: 15px;
		padding: 0 10px;
		border: 2px solid $rosa;
		border-radius: 10px;
		font-family: Quicksand;
		font-size: 1rem;
		color: $fucsia;

		&:focus {
			outline: none;
			border-color: $fucsia;
			color: #000;
		}
	}

	textarea {
		appearance: none;
		width: 60%;
		height: 150px;
		margin-bottom: 15px;
		padding: 10px;
		border: 2px solid $rosa;
		border-radius: 10px;
		font-family: Quicksand;
		font-size: 1rem;
		color: $fucsia;
		resize: none;
		&:focus {
			outline: none;
			border-color: $fucsia;
			color: #000;
		}
	}

	span {
		display: block;
		width: 60%;
		margin-bottom: 10px;
		font-size: 0.9rem;
		text-align: center;
	}

	input[type="submit"] {
		appearance: none;
		width: 60%;
		height: 40px;
		margin-top: 10px;
		margin-bottom: 15px;
		border: none;
		border-radius: 10px;
		background-color: $rosa;
		font-family: Quicksand;
		font-size: 1rem;
		&:hover {
			cursor: pointer;
			background-color: $fucsia;
		}
	}

	.privacy-btn {
		font-size: 0.9rem;
		color: $fucsia;
		&:hover {
			cursor: pointer;
			color: $rosa;
		}
	}

	p {
		width: 60%;
		font-size: 1rem;
		text-align: center;
	}

	.error {
		color: #f00;
	}

	.feedback {
		color: $verde;
	}

	@media screen and (max-width: 999px) {
		input[type="text"],
		input[type="submit"],
		textarea,
		span,
		p {
			width: 75%;
		}
	}

	@media screen and (max-width: 499px) {
		input[type="text"],
		input[type="submit"],
		textarea,
		span,
		p {
			width: 100%;
		}
	}
}
