#article {
	.article-header {
		margin-top: 60px;
		text-align: center;

		.article-title {
			padding-top: 25px;
			text-transform: uppercase;
			color: $verde;
			font-family: Quicksand;
			font-size: 2.5rem;
		}

		.article-date {
			padding: 10px 0 25px;
			font-family: Quicksand;
			font-size: 1rem;
			color: #545454;
		}

		.article-image {
			min-width: 300px;
			max-width: 50%;
		}
	}

	.article-content {
		text-align: justify;
		font-size: 1.2rem;
		padding: 25px 100px 25px;

		ul {
			padding: revert;
			li {
				list-style-image: url("/img/list-style-image.png");
				list-style-type: circle;
				margin: 10px 0;
			}
		}

		.num-list {
			li {
				list-style-image: none;
				list-style-type: decimal;
			}
		}

		.paragraph {
			font-family: Quicksand;
			margin-bottom: 25px;
			line-height: 1.5rem;

			strong {
				font-weight: 400;
				font-family: Quicksand-Bold;
			}

			b {
				font-weight: 400;
				font-family: Quicksand-Bold;
				text-transform: uppercase;
			}

			a {
				color: $fucsia;
				&:hover {
					cursor: pointer;
					color: $rosa;
				}
			}
		}

		.titoletto {
			margin-top: 40px;
			margin-bottom: 10px;
			font-family: Quicksand;
			text-transform: uppercase;
			color: $verde;
			line-height: 1.5rem;
		}

		.image {
			text-align: center;
			margin-bottom: 25px;

			img {
				max-width: 100%;
				min-width: 250px;
			}
		}
	}

	@media screen and (max-width: 799px) {
		.article-content {
			padding: 25px;
		}
	}

	@media screen and (max-width: 249px) {
		.article-header {
			.article-image {
				width: 200px;
			}
		}
	}
}
