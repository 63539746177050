header {
	width: 100%;
	position: fixed;
	top: 0;
	font-family: Aloja;
	z-index: 9999;

	.menu-pc {
		height: 60px;
		background-color: rgba($verde, 0.75);
		display: flex;
		align-items: center;
		text-transform: uppercase;

		.menu-item {
			width: calc(100% / 5);
			text-align: center;

			a {
				display: inline-block;
				height: 100%;
				width: 100%;
				font-size: 1.2rem;
				color: $rosa;

				&:hover {
					cursor: pointer;
					color: $fucsia;
				}

				&.selected {
					color: $fucsia;
				}
			}
		}
	}

	.menu-mobile {
		display: none;
		text-transform: uppercase;

		.hamburger {
			height: 60px;
			background-color: rgba($verde, 0.75);
			display: flex;
			align-items: center;
			justify-content: center;
			color: $rosa;

			&:hover {
				cursor: pointer;
			}

			.fas {
				font-size: 1.8rem;
			}
		}

		.dropdown {
			width: 100%;
			@include page-height;
			background-color: rgba($verde, 0.75);
			position: absolute;
			top: -1999px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			transition: top 0.5s;

			li {
				text-align: center;

				a {
					display: block;
					font-size: 1.5rem;
					color: $rosa;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}

		&.close {
			height: 60px !important;
		}

		&.open {
			.hamburger {
				background-color: rgba($verde, 0.85);
			}

			.dropdown {
				background-color: rgba($verde, 0.85);
				height: calc(100% - 60px);
				top: 60px;
				transition: top 0.5s;
			}
		}
	}

	@media screen and (max-width: 999px) {
		.menu-pc {
			display: none;
		}

		.menu-mobile {
			display: block;
		}
	}
}
