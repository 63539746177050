#blog {
	.page-header {
		background-image: url("/img/unfioredimaestra-home.jpg");
		background-position: top center;

		.page-title {
			width: 45%;
		}
	}

	.page-content {
		.blog-list {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;

			.article {
				margin-bottom: 25px;
				width: 47%;
				text-align: center;

				.link {
					display: block;
					width: 100%;
					height: 100%;
					border: 1px dotted $fucsia;

					.article-title {
						margin: 10px;
						font-size: 1.7rem;
						font-family: Quicksand;
						color: $verde;
						text-transform: uppercase;
					}

					.article-image {
						width: 70%;
					}

					.article-preview {
						width: 70%;
						margin: 15px auto;
						font-size: 1rem;
						color: #000;
					}
				}
			}
		}
	}

	@media screen and (max-width: 999px) {
		.page-header {
			.page-title {
				width: 65%;
			}
		}
	}

	@media screen and (max-width: 599px) {
		.page-content {
			.blog-list {
				flex-wrap: wrap;

				.article {
					width: 100%;

					.link {
						.article-image {
							width: 80%;
						}

						.article-preview {
							width: 80%;
						}
					}
				}
			}
		}
	}
}
