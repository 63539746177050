#contatti {
	.page-header {
		background-image: url("/img/unfioredimaestra-contatti.jpg");
		background-position: left center;

		.page-title {
			width: 45%;
		}
	}

	.page-content {
		display: flex;

		.left {
			width: 50%;

			.contacts {
				margin-bottom: 50px;

				p {
					margin-bottom: 5px;
				}
				
				a {
					color: $fucsia;
					&:hover {
						cursor: pointer;
						color: $rosa;
					}
				}

				.instagram {
					width: fit-content;
					display: flex;
					align-items: center;
					font-size: 1.5rem;

					.user {
						margin-left: 10px;
						font-size: 1.2rem;
					}
				}
			}

			.school {
				margin: 20px 0;
				line-height: 1.5rem;

				.school-name {
					color: #000;
					font-weight: 400;
					font-family: Quicksand-Bold;
					&:hover {
						color: $fucsia;
					}
				}

				.school-address {
					b {
						font-weight: 400;
						font-family: Quicksand-Bold;
					}
				}

				.school-course {
					font-style: oblique;
				}

				a {
					color: $fucsia;
					&:hover {
						color: $rosa;
						cursor: pointer;
					}
				}
			}
		}

		.right {
			width: 50%;

			.contact-form {
				.scrivimi {
					margin-bottom: 15px;
					text-align: center;
					font-size: 1rem;
					color: #000;
				}
			}

			.logo {
				text-align: center;

				img {
					width: 200px;
				}
			}
		}
	}

	@media screen and (max-width: 999px) {
		.page-header {
			background-size: 130%;
			background-position: left center;
			background-repeat: no-repeat;

			.page-title {
				width: 55%;
			}
		}
	}

	@media screen and (max-width: 799px) {
		.page-content {
			flex-direction: column;

			.left {
				width: 100%;
				margin-bottom: 40px;
			}

			.right {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 499px) {
		.page-header {
			.page-title {
				width: 75%;
			}
		}
	}
}
