* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

li {
	list-style-type: none;
}

a {
	text-decoration: none;
}

// SECTIONS
section {
	.page-header {
		height: 50vh;
		background-size: cover;
		position: relative;

		.page-title {
			background-image: url("/img/unfioredimaestra-pennellata.png");
			background-repeat: no-repeat;
			background-size: 100% 85%;
			padding: 40px 20px 50px 50px;
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 55%);
			text-align: center;
			text-transform: uppercase;
			font-family: Aloja;
			font-size: 3rem;
			color: $fucsia;
		}
	}

	.page-content {
		padding: 100px 100px 25px;
		font-family: Quicksand;
		font-size: 1.2rem;
		background-color: white;
	}

	@media screen and (max-width: 799px) {
		.page-header {
			height: 30vh;

			.page-title {
				left: 50%;
				transform: translate(-50%, 60%);
				font-size: 2rem;
				padding: 30px 20px 50px 50px;
			}
		}

		.page-content {
			padding: 100px 25px 25px;
		}
	}
}
