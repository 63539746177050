#viaggio-musicale {
	.page-header {
		background-image: url("/img/unfioredimaestra-viaggio-musicale.jpg");
		background-position: left center;

		.page-title {
			width: 60%;
		}
	}

	.page-content {
		text-align: justify;

		.paragraph {
			margin-bottom: 20px;
			line-height: 1.5rem;

			strong {
				font-weight: 400;
				font-family: Quicksand-Bold;
			}

			.equipaggiamento {
				li {
					margin-bottom: 8px;

					strong {
						font-weight: 400;
						font-family: Mission;
						color: $verde;
						letter-spacing: 0.5px;
						font-size: 1.5rem;
					}
				}
			}
		}
	}

	@media screen and (max-width: 999px) {
		.page-header {
			.page-title {
				width: 75%;
			}
		}
	}
}
