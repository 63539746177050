$rosa: #ffccc9;
$fucsia: #ff5666;
$verde: #074f57;

@font-face {
	font-family: Aloja;
	src: url("../storage/fonts/aloja.otf");
}

@font-face {
	font-family: Amsterdam;
	src: url("../storage/fonts/amsterdam.otf");
}

@font-face {
	font-family: Quicksand;
	src: url("../storage/fonts/quicksand.otf");
}

@font-face {
	font-family: Quicksand-Bold;
	src: url("../storage/fonts/quicksand-bold.otf");
}

@font-face {
	font-family: Mission;
	src: url("../storage/fonts/mission.ttf");
}

// VH
@mixin page-height {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}
