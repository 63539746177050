#chi-sono {
	.page-header {
		background-image: url("/img/unfioredimaestra-chi-sono.jpg");
		background-position: center top;

		.page-title {
			width: 45%;
		}
	}

	.page-content {
		text-align: justify;

		.paragraph {
			margin-bottom: 20px;
			line-height: 1.5rem;

			strong {
				font-weight: 400;
				font-family: Quicksand-Bold;
			}

			b {
				display: block;
				margin-top: 40px;
				text-transform: uppercase;
				font-family: Quicksand;
			}

			li {
				margin: 10px 0;

				a {
					color: $fucsia;
					&:hover {
						cursor: pointer;
						color: $rosa;
					}
				}
			}
		}
	}

	@media screen and (max-width: 999px) {
		.page-header {
			background-image: url("/img/unfioredimaestra-chi-sono-mobile.jpg");

			.page-title {
				width: 55%;
			}
		}
	}

	@media screen and (max-width: 799px) {
		.page-header {
			background-size: 140%;
		}
	}

	@media screen and (max-width: 499px) {
		.page-header {
			background-size: 200%;

			.page-title {
				width: 75%;
			}
		}
	}
}
