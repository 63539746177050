#not-found {
	width: 100vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: $verde;

	.page-content {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		h2 {
			color: $fucsia;
			font-family: Aloja;
			font-size: 4rem;
			text-align: center;
		}
	}
}
