#home {
	position: fixed;
	width: 100%;
	height: 100%;
	background-image: url("/img/unfioredimaestra-home.jpg");
	background-size: cover;
	background-position-x: 35%;

	.mission {
		width: 100%;
		background-color: rgba($verde, 0.75);
		position: absolute;
		bottom: 0;
		padding: 10px 30px;
		display: flex;
		align-items: center;

		.logo {
			height: 150px;
		}

		p {
			margin-left: 75px;
			font-family: Mission;
			font-size: 2rem;
			color: $rosa;
		}
	}

	// MOBILE
	@media screen and (max-width: 799px) {
		.mission {
			padding: 10px;
			.logo {
				height: 120px;
			}

			p {
				margin-left: 30px;
				font-size: 1.6rem;
			}
		}
	}

	@media screen and (max-width: 599px) {
		.mission {
			flex-direction: column;
			text-align: center;
			bottom: -100%;
			animation-name: show-mission;
			animation-duration: 3s;
			animation-fill-mode: forwards;
		}

		@keyframes show-mission {
			from {
				bottom: -100%;
			}
			to {
				bottom: 0;
			}
		}
	}
}
